import React, { useContext } from 'react'
import SignedInHeader from './navbar/SignedInHeader'
import Sidebar from './sidebar/Sidebar'
import MainContent from './content/MainContent'
import Notifications from './notifications/Notifications'
import { AuthContext } from '../../auth/Auth';
import './main.css'
import { MainContext } from './MainContext'

const Main = () => {
    const { admin } = useContext( AuthContext );
    const { keyDownListener, keyUpListener } = useContext( MainContext );

    return (
        <div tabIndex="0" onKeyDown={ keyDownListener } onKeyUp={ keyUpListener } >
            <SignedInHeader />
            <div id="main-wrapper">
                <Sidebar />
                <MainContent/>
                { admin ? <Notifications /> : null }
            </div>
        </div>
    )
}

export default Main