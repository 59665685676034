import React, { useContext } from 'react'
import '../mainContent.css'
import { IngredientContext } from './IngredientContext';

const AddNutrient = () => {
    const { componentState, addNutrient } = useContext(IngredientContext);

    const handleClick = (e) => {
        e.preventDefault();
        addNutrient();
    };
    return (
        <button onClick={ handleClick } id="add-nutrient" className={ componentState.nutrientValidation ? "active" : "" }>
            <p>+ Nutriente</p>
        </button>
    )
}

export default AddNutrient
