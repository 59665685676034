import React, { useContext } from 'react'
import { InfographicContext } from './InfographicContext'
import ImageSelector from './ImageSelector';
import { ReactComponent as TrashcanLid } from '../../../../../img/icons/trashcan-lid.svg'
import { ReactComponent as TrashcanBody } from '../../../../../img/icons/trashcan-body.svg'

const AddNewInfographic = ({ togglePopup }) => { 
    const { categoryList, closeInfographicEditor, currentInfographic, deleteInfographicFromDatabase, handleUploadInfographicFile, infographicsState, updateCurrentInfographic, uploadInfographicToDatabase } = useContext( InfographicContext )

    const handleFieldChange = e => {
        updateCurrentInfographic( e.target.name,  e.target.value );
    };

    const handleFileUploadClick = e => {
        e.preventDefault();
        document.querySelector(`#new-infographic-button-row-1 .new-infographic-photo-upload`).click();
    };
    const deleteButtonClass = () => {
        var className = 'width-100';
        const state = infographicsState;
        ( state.updatingInfographic ? className = className + ' button-3' : className = className + ' button-4' );
        return className;
    };

    const handleDeleteInfographic = e => {
        e.preventDefault();
        if (infographicsState.updatingInfographic) {
            const infographicID = infographicsState.updatingInfographic;
            deleteInfographicFromDatabase( infographicID );
        } else {
            closeInfographicEditor();
        };
    }
    const handlePublish = e => {
        uploadInfographicToDatabase( e );
    }

    return (
        <div id="add-infographic-wrapper">
            <div id="image-selector">
                <ImageSelector/>
            </div>
            <div id="current-image-wrapper" className="simple-bg overflow-hidden">
                <div id="current-image">
                </div>
            </div>
            <form id="infographic-form" className="dark-bg">
                <div className="input-container-1 title">
                    <div className="input-label-1 grey-label">Título</div>
                    <input onChange={ handleFieldChange } type="text" id="title" name="title" spellCheck="false"/>
                </div>
                <div className="input-container-1 title">
                    <div className="input-label-1 grey-label">Categoría</div>
                    <select id="category-selector" name="category" onChange={ handleFieldChange } defaultValue="">
                        <option value="" > Seleccionar categoría... </option>
                        { categoryList[0] && categoryList.map( category => {
                            return(
                                <option key={ category.key } value={ category.id } > { category.name } </option>
                            )
                        })}
                    </select>
                </div>
                <div id="new-infographic-button-row-1">
                    <button id="upload-file-button" className={ !currentInfographic.downloadableFile.isUploaded ? "button-1" : "button-4" } onClick={ handleFileUploadClick } > Subir PDF </button>
                    <button className={ !currentInfographic.downloadableFile.isUploaded ? "button-0 empty" : "button-0" } onClick={ e => e.preventDefault() }>{ currentInfographic.downloadableFile.name }</button>
                    <input type="file" className="new-infographic-photo-upload" onChange={ handleUploadInfographicFile } accept=".pdf" ></input>
                </div>
                <div className="align-center">
                    <button className={ currentInfographic.downloadableFile.isUploaded && currentInfographic.title && currentInfographic.category ? "button-1" : "button-1 deactivated" } onClick={ currentInfographic.downloadableFile.isUploaded && currentInfographic.title && currentInfographic.category ? e => { e.preventDefault(); togglePopup() } : e => e.preventDefault() } > Editar Contenido </button>
                </div>
                <div id="new-infographic-button-row-2">
                    <button id='delete-button' className={ deleteButtonClass() } onClick={ handleDeleteInfographic } >
                            <div id="trashcan-div">
                                <TrashcanLid />
                                <TrashcanBody />
                            </div>
                    </button>
                    <button className={ currentInfographic.title && currentInfographic.category ? "button-2" : "button-2 deactivated" } onClick={ currentInfographic.title && currentInfographic.category ? uploadInfographicToDatabase : e => e.preventDefault() } >{( infographicsState.updatingInfographic ? 'Actualizar' : 'Añadir ' )} </button>
                    <button id='publish-infographic-button' className={ currentInfographic.downloadableFile.isUploaded && currentInfographic.content && currentInfographic.title && currentInfographic.category ? "button-1" : "button-1 deactivated" } onClick={ currentInfographic.downloadableFile.isUploaded && currentInfographic.content && currentInfographic.title && currentInfographic.category ? handlePublish : e => e.preventDefault() } > Publicar </button>
                </div>
            </form>
        </div>
    )
}

export default AddNewInfographic
