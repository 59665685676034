import React, { useContext, useEffect } from 'react'
import { InfographicContext } from './InfographicContext'
import AddNewImage from './AddNewImage'
import InfographicImageCard from './InfographicImageCard'

const InfographicPopupPicture = () => { 
    const { currentInfographicPictures } = useContext( InfographicContext );

    return (
        <div id='infographic-popup-image' className="simple-bg" style={{ backgroundImage: `url(${ currentInfographicPictures[0].photoURL })` }}>
            <div></div>
        </div>
    )
}

export default InfographicPopupPicture
