import React, { useContext, useEffect } from 'react'
import { InfographicContext } from './InfographicContext'
import AddNewImage from './AddNewImage'
import InfographicImageCard from './InfographicImageCard'

const ImageSelector = () => { 
    const { currentInfographicPictures } = useContext( InfographicContext );

    return (
        <ul id="image-selector-wrapper" className="dark-bg no-padding no-border-radius">
            { currentInfographicPictures[0] && currentInfographicPictures.map( picture => {
                    return(
                        <li key={ picture.key }><InfographicImageCard pictureKey={ picture.key } photoURL={ picture.photoURL } /></li>
                    )
            })}
            <li><AddNewImage /></li>
        </ul>
    )
}

export default ImageSelector
