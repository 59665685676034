import React from 'react'
import { ReactComponent as MainLogo } from '../../../../img/logo-main.svg'
import './signedInHeader.css'

const SignedInHeader = () => {
    return (
        <header id="main-header">
            <div className="logo">
                <MainLogo />
            </div>
            <div id="main-grad"></div>
       </header> 
    )
}

export default SignedInHeader;