import React from 'react'
import './mainContent.css'

const AllergenSelector = (props) => {
    return(
        <div id="allergen-selector">
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.mollusks ? "allergen active" : "allergen" } id="mollusks"><img className="no-events" src={require("../../../../img/allergens/mollusks.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.crustaceans ? "allergen active" : "allergen" } id="crustaceans"><img className="no-events" src={require("../../../../img/allergens/crustaceans.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.fish ? "allergen active" : "allergen" } id="fish"><img className="no-events" src={require("../../../../img/allergens/fish.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.lupins ? "allergen active" : "allergen" } id="lupins"><img className="no-events" src={require("../../../../img/allergens/lupins.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.mustard ? "allergen active" : "allergen" } id="mustard"><img className="no-events" src={require("../../../../img/allergens/mustard.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.eggs ? "allergen active" : "allergen" } id="eggs"><img className="no-events" src={require("../../../../img/allergens/eggs.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.gluten ? "allergen active" : "allergen" } id="gluten"><img className="no-events" src={require("../../../../img/allergens/gluten.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.treeNuts ? "allergen active" : "allergen" } id="treeNuts"><img className="no-events" src={require("../../../../img/allergens/tree-nuts.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.peanuts ? "allergen active" : "allergen" } id="peanuts"><img className="no-events" src={require("../../../../img/allergens/peanuts.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.dairy ? "allergen active" : "allergen" } id="dairy"><img className="no-events" src={require("../../../../img/allergens/dairy.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.sulphur ? "allergen active" : "allergen" } id="sulphur"><img className="no-events" src={require("../../../../img/allergens/sulphur.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.sesameGrains ? "allergen active" : "allergen" } id="sesameGrains"><img className="no-events" src={require("../../../../img/allergens/sesame-grains.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.celery ? "allergen active" : "allergen" } id="celery"><img className="no-events" src={require("../../../../img/allergens/celery.png")} alt="Smiley face" height="20" width="20"/></button>
            <button onClick={ props.handleAllergenClick } className={ props.allergenState.soy ? "allergen active" : "allergen" } id="soy"><img className="no-events" src={require("../../../../img/allergens/soy.png")} alt="Smiley face" height="20" width="20"/></button>
        </div>
    )
}

export default AllergenSelector
