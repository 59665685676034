import React from 'react'
import './mainContent.css'

const Dashboard = () => {
    return (
        <div className="content-wrapper">
            <h1>Dashboard</h1>
        </div>
    )
}

export default Dashboard
