import { useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const hide = () => {
    setIsShowing( false );    
    document.querySelector('#infographic-popup-overlay').classList.remove('active');
  }

  const animatePopup = ( content ) => {
    document.querySelector('#infographic-popup-overlay').classList.add('active');
    document.querySelector('#infographic-popup-image').classList.add('active');
    setTimeout(() => {
      document.querySelector('#popup-anim').classList.add('active');
      document.querySelector('#infographic-popup').classList.add('active');
      document.querySelector('#popup-content').classList.add('active');
      document.querySelector('#infographic-popup-image-selector').classList.add('active');
      document.querySelector('#popup-content .textarea-1').value = content;
    }, 300)
  }

  const show = ( content = '' ) => {
    setIsShowing( true );
    setTimeout(() => {
      animatePopup( content );
    }, 200);
  }

  return {
    isShowing,
    hide,
    show
  }
};

export default useModal;
