import React from 'react'
import './notifications.css'

const Notifications = () => {
    return (
        <div id="notifications" className="admin">
            <div className="simple-bg">
                <div className="padding16">
                    <h1>Notificaciones</h1>
                </div>
                <div className="grad-1"></div>
            </div>
        </div>    
    )
}

export default Notifications
