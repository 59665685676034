import React, { useContext } from 'react'
import SearchBar1 from '../../SearchBar1'
import AllergenSelector from '../AllergenSelector'
import IngredientList from './IngredientList'
import NutrientList from './NutrientList'
import { IngredientContext } from './IngredientContext'
import { ReactComponent as TrashcanLid } from '../../../../../img/icons/trashcan-lid.svg'
import { ReactComponent as TrashcanBody } from '../../../../../img/icons/trashcan-body.svg'

const Ingredients = () => {

    const { addNewIngredientToDatabase, componentState, currentIngredient, deleteIngredient, resetInputs, updateAllergen, updateIngredientInDatabase, updateMacro, updateName, resetCurrentIngredientState } = useContext(IngredientContext);
    
    /* Event Handling */
    const handleAllergenClick = (e) => {
        e.preventDefault();
        e.persist();
        const targetID = e.target.id;
        updateAllergen( targetID );
    }
    const handleNameInput = (e) => {
        const name = e.target.value;
        updateName( name );
    };
    const resetIngredient = () => {
        resetCurrentIngredientState();
        resetInputs();
    };

    /* Button Classes */
    const handleMacroInput = (e) => {
        const macro = e.target.id;
        const value = e.target.value;
        updateMacro( macro, value );
    };
    const submitButtonClass = () => {
        var className = 'width-100';
        ( componentState.updating ? className = className + ' button-2' : className = className + ' button-1' );
        ( componentState.nutrientValidation && componentState.macroValidation ? className = className + '' : className = className + ' deactivated' );
        return className;
    };
    const deleteButtonClass = () => {
        var className = 'width-100';
        const state = componentState;
        ( state.updating ? className = className + ' button-3' : className = className + ' button-4' );
        return className;
    };

    return (
            <div id="content-wrapper">
                <div className="simple-bg border-radius-5-10">
                    <div className="padding16">
                        <h1>Ingredientes</h1>
                    </div>
                    <div className="grad-1 no-border-radius"></div>
                    <div className="dark-bg">
                        <form autoComplete="off">
                            <div id="ingredient-form">
                                <div className="input-container-1 name">
                                    <div className="input-label-1 grey-label">Nombre</div>
                                    <input onChange={handleNameInput} type="text" id="name" spellCheck="false"/>
                                </div>
                                <div className="input-container-1 recipe label100">
                                    <div className="input-label-1 grey-label">Asociar Receta</div>
                                    <input type="text" id="recipe"  spellCheck="false"/>
                                </div>
                                <div className="input-container-1 fiber">
                                    <div className="input-label-1 grey-label">Fibra</div>
                                    <input onChange={handleMacroInput} type="text"  id="fiber" className="align-center"  spellCheck="false"/>
                                </div>
                                <div className="input-container-1 protein">
                                    <div className="input-label-1 grey-label">Proteínas</div>
                                    <input onChange={handleMacroInput} type="text" id="protein" className="align-center"  spellCheck="false"/>
                                </div>
                                <div className="input-container-1 carbohydrates">
                                    <div className="input-label-1 grey-label">Hidratos</div>
                                    <div className="double-input"> 
                                        <input onChange={handleMacroInput} type="text" id="carbohydrates" className="align-center"  spellCheck="false"/>
                                        <span className="center-border"></span>
                                        <input onChange={handleMacroInput} type="text" id="sugars" className="align-center"  spellCheck="false"/>
                                    </div>
                                </div>
                                <div className="input-container-1 fat">
                                    <div className="input-label-1 grey-label">Grasas</div>
                                    <div className="double-input"> 
                                        <input onChange={handleMacroInput} type="text" id="fat" className="align-center"  spellCheck="false"/>
                                        <span className="center-border"></span>
                                        <input onChange={handleMacroInput} type="text" id="saturatedFat" className="align-center"  spellCheck="false"/>
                                    </div>
                                </div>
                            </div>
                            <NutrientList />
                        </form>
                        <div id="ingredient-button-row">
                            <AllergenSelector handleAllergenClick={handleAllergenClick} allergenState={currentIngredient.allergens}/>
                            <SearchBar1/>
                            <div id='ingredient-buttons'>
                                <button id='delete-button' className={ deleteButtonClass() } onClick={ componentState.updating ? deleteIngredient : resetIngredient} >
                                    <div id="trashcan-div">
                                        <TrashcanLid />
                                        <TrashcanBody />
                                    </div>
                                </button>
                                <button id='submit-ingredient' className={ submitButtonClass() } onClick={ ( componentState.updating ? updateIngredientInDatabase : addNewIngredientToDatabase ) } >{ componentState.updating ? 'Actualizar' : 'Añadir' }</button>
                            </div>
                        </div>
                    </div>
                </div>
                <IngredientList/>
            </div>
    )
}

export default Ingredients
