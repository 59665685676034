import React, { useContext, useState } from 'react'
import '../mainContent.css'
import { InfographicContext } from './InfographicContext';
import { MainContext } from '../../MainContext';
import { ReactComponent as PictureFrame } from '../../../../../img/icons/picture-frame.svg'
import { storage } from '../../../../../config/fbConfig'

const InfographicImageCard = ( props ) => {
    const { changeSelectedImage, infographicsState } = useContext( InfographicContext );

    const handlePictureClick = e => {
        e.preventDefault();
        changeSelectedImage( props.pictureKey, props.photoURL );
    };
    /* Event Handling */
    return (
        <button className={ infographicsState.selectedPictureKey === props.pictureKey ? 'infographic-image-card active' : 'infographic-image-card'} onClick={ handlePictureClick } style={{ backgroundImage: `url(${props.photoURL})` }}>
        </button>
    )
}

export default InfographicImageCard