import React, { useContext } from 'react'
import { InfographicContext } from './InfographicContext'
import InfographicCard from './InfographicCard'

const InfographicsList = () => {
    const { filteredInfographicList } = useContext( InfographicContext )

    return (
        <div id="infographics-list">
            <ul id="infographics-ul">
                { filteredInfographicList[0] && filteredInfographicList.map( infographic => {
                    return (
                        <li key={ infographic.key } ><InfographicCard id={ infographic.id } mainImage={ infographic.mainImage } published={ infographic.published } /></li>
                    )
                }) }
            </ul>
        </div>
    )
}

export default InfographicsList
