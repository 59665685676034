import React from 'react'
import AddInfographics from './AddInfographics'
import { InfographicProvider } from './InfographicContext'

const InfographicsWidget = () => {
    return (
        <InfographicProvider>
            <AddInfographics />
        </InfographicProvider>
    )
}

export default InfographicsWidget
