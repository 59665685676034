import React, { Component } from 'react'
import RecipeCard from './RecipeCard'
import '../mainContent.css'

class NewRecipe extends Component {
    state = {
        id: '',
        name: '',
        img: '',
        intro: '',
        ingredients: '',
        materials: '',
        steps: '',
        video: '',
        time: '',
        publishingDate: '',
        asociateIngredient: '',
        tags: '',
        showNewTag: '',
        showEditedTag: '',
        published: '',
        createdBy: '',
        lastEditedBy: ''
    }
    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        })
    }
    render(){
        return (
            <div className="content-wrapper">
                <div className="simple-bg">
                    <div className="padding16">
                        <h1>Nueva Receta</h1>
                        <div className="new-recipe-card-wrapper">
                            <RecipeCard recipe={this.state}/>
                        </div>
                        <form id="new-recipe-form" name="new-recipe-form">
                            <div className="input-container-1">
                                <div className="input-label-1">Intro</div>
                                <textarea onChange={this.handleChange} id="intro" rows="1" />
                            </div>
                            <div className="input-container-1">
                                <div className="input-label-1">Nombre</div>
                                <input onChange={this.handleChange} id="name" />
                            </div>
                        </form>
                    </div>
                    <div className="grad-1"></div>
                </div>
            </div>
        )
    }
}

export default NewRecipe