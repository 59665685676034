import React, { useContext } from 'react'
import AddNutrient from './AddNutrient'
import NutrientCard from './NutrientCard'
import { IngredientContext } from './IngredientContext'
import '../mainContent.css'

const NutrientList = () => {
    const { nutrients } = useContext( IngredientContext );

    return (
        <div id="nutrients">
            { nutrients[0] && nutrients.map( nutrient => {
                return(
                    <NutrientCard key={ nutrient.key } nutrientKey={ nutrient.key } />
                )
            })}
            <AddNutrient />
        </div>     
    )
}

export default NutrientList
