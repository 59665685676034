import React from 'react'
import InfographicCategoriesList from './InfographicCategoriesList'

const InfographicCategories = () => {
    
    return (
        <div id="infographic-categories-wrapper">
            <InfographicCategoriesList />
        </div>
    )
}

export default InfographicCategories
