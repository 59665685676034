import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { db } from '../../config/fbConfig'


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    /* Base States */
    const authDataBase = {
        currentUser: null,
        uid: null,
        email: null,
        nick: null,
        photoURL: null,
        admin: null,
        tier: null
    };
    const userDataBase = {
        name: null,
        lastName: null,
        title: null,
        frame: null,
        initials: null
    };

    /* State Definition */
    const [ currentUser, setCurrentUser ] = useState(null);
    const [ authData, setAuthData ] = useState( authDataBase );
    const [ userData, setUserData ] = useState( userDataBase );
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged(setCurrentUser);

    }, []);
    useEffect(() => {
        if (currentUser) {
            firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
                setAuthData({
                    currentUser: currentUser,
                    uid: currentUser.uid,
                    email: currentUser.email,
                    nick: currentUser.displayName,
                    photoURL: currentUser.photoURL,
                    admin: idTokenResult.claims.admin,
                    tier: idTokenResult.claims.tier
                });
                db.collection('users').doc(currentUser.uid).get().then(doc => {
                    const currentUserData = doc.data();
                    setUserData({
                        name: currentUserData.name,
                        lastName: currentUserData.lastName,
                        title: currentUserData.title,
                        frame: currentUserData.frame,
                        initials: currentUserData.initials
                    })
                });                  
            });
        } else {
            setAuthData({ authDataBase });
            setUserData({ userDataBase });
        }
    }, [currentUser]);

    var value = {
        currentUser,
        ...authData,
        ...userData
    };

    return (
        <AuthContext.Provider value = {value}>
            { children }
        </AuthContext.Provider>
    )
};