import React, { useContext } from 'react'
import { InfographicContext } from './InfographicContext'

const AddNewInfographicCategory = () => {

    const { addCategory, categoryListValidation } = useContext( InfographicContext );

    /* Handle Events */
    const handleAddCategoryClick = () => {
        addCategory();
    }

    return (
        <div id="add-infographic-category-wrapper" className={ categoryListValidation ? "infographic-category allowed" : "infographic-category" }>
            <button onClick={ categoryListValidation ? handleAddCategoryClick : null } className='infographic-category-photo-wrapper' id="add-infographic-category" >
                <div className='infographic-category-photo'>
                </div>
                <p className="add-plus-sign-20">+</p>
            </button>
            <input className="infographic-category-name" value={ 'Añadir Categoría' } readOnly ></input>
        </div>
    )
}

export default AddNewInfographicCategory
