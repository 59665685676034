import React, { useContext } from 'react'
import '../mainContent.css'
import { IngredientContext } from './IngredientContext'

const IngredientList = () => {
    const { ingredientList, loadIngredient, setUpdateState } = useContext( IngredientContext );
        
    const handleIngredientClick = ( e ) => {
        e.preventDefault();
        const element = e.target;
        const prevActive = document.querySelector( '.content-card.active' );
        if ( element.classList.toggle( 'active' ) ) {
            if (prevActive) {
                prevActive.classList.remove( 'active' );
            }
            loadIngredient( e.target.id );
        } else {
            setUpdateState( false );
        }
    };
    return (
        <div id="ingredient-list" className="">
            { ingredientList && ingredientList.map( ingredient => {
                return(
                    <button className="content-card" onClick={ handleIngredientClick } name={ ingredient.name } id={ ingredient.id } key={ ingredient.id } >{ ingredient.name }</button>
                )
            })
            }
        </div>     
    )
}

export default IngredientList
