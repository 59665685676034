import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import Preland from './components/layout/landing/Preland'
import Main from './components/layout/app/Main'
import { AuthProvider } from "./components/auth/Auth"
import PrivateRoute from "./components/auth/PrivateRoute"
import UnsignedRoute from "./components/auth/UnsignedRoute"
import { MainProvider } from './components/layout/app/MainContext'

const App = () => {
  return (
    <AuthProvider>
      <MainProvider>
        <BrowserRouter>
          <div id="App">
            <Switch>
              <UnsignedRoute exact path="/signin" component={Preland} />
              <PrivateRoute path="/" component={Main} />
            </Switch>
          </div>
        </BrowserRouter>
      </MainProvider>
    </AuthProvider>
  );
}

export default App;
