import React, { useContext, useEffect } from 'react'
import { InfographicContext } from './InfographicContext'
import AddNewImage from './AddNewImage'
import InfographicImageCard from './InfographicImageCard'

const InfographicPopupImageSelector = () => {
    const { currentInfographic } = useContext( InfographicContext );

    return (
        <div id='infographic-popup-image-selector' className="simple-bg" >
            <div>
                
            </div>
        </div>
    )
}

export default InfographicPopupImageSelector
