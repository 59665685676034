import React, { useContext } from 'react'
import '../mainContent.css'
import { IngredientContext } from './IngredientContext';

const NutrientCard = (props) => {

    /* State Definition */
    const { updateNutrient, deleteNutrient } = useContext( IngredientContext)

    /* Event Handling */
    const handleInput = (e) => {
        e.preventDefault();
        const key = props.nutrientKey;
        const field = e.target.name;
        const value = e.target.value;
        updateNutrient( key, field, value );
    };
    const handleKeydown = (e) => {
        const pressedKey = e.key;
        if ( pressedKey === 'Delete' && e.ctrlKey ) {
            deleteNutrient( props.nutrientKey );
        }
    };
    return (
        <div className={ `nutrient-card key-${ props.nutrientKey }` } >
            <div className="input-container-1 cols2-1 nutrient">
                <div className="input-label-1 grey-label nutrient-title">
                    <input onChange={ handleInput } onKeyDown={ handleKeydown } name="name" type="text" className="align-center name-field" spellCheck="false"/>
                </div>
                <input onChange={ handleInput } onKeyDown={ handleKeydown } name="value" type="text" className="align-center value-field" spellCheck="false"/>
            </div>
        </div>
    )
}

export default NutrientCard