import React, { useContext } from 'react'
import { InfographicContext } from './InfographicContext'

const AddNewImage = () => {
     
    const { uploadInfographicPhoto } = useContext( InfographicContext );

    const handleAddNewInfographicClick = e => {
        e.preventDefault();
        document.querySelector(`#add-new-image-wrapper .new-infographic-photo-upload`).click();
    }

    return (
        <div id="add-new-image-wrapper">
            <button onClick={ handleAddNewInfographicClick } id="add-infographic-images" className="active">
                <div className='add-infographic-photo'>
                <p className="add-plus-sign-20">+</p>
                </div>
            </button>
            <input type="file" className="new-infographic-photo-upload" onChange={ uploadInfographicPhoto } accept="image/png, image/jpeg" multiple={ true } ></input>
        </div>
    )
}

export default AddNewImage
