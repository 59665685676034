import React from 'react';
import ReactDOM from 'react-dom';
import InfographicPopupPicture from './InfographicPopupPicture';
import InfographicPopupContent from './InfographicPopupContent';
import InfographicPopupImageSelector from './InfographicPopupImageSelector';

const InfographicPopup = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div id='infographic-popup-overlay' className="popup-overlay" onClick={ hide }>
      <div id='infographic-popup' onClick={ e => { e.stopPropagation() }}>
        <div id='popup-anim'>
          <InfographicPopupImageSelector/>
          <InfographicPopupPicture/>
          <InfographicPopupContent/>
        </div>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default InfographicPopup;