import React, { useContext } from 'react'
import AddNewInfographicCategory from './AddNewInfographicCategory'
import InfographicCategoryCard from './InfographicCategoryCard'
import '../mainContent.css'
import { InfographicContext } from './InfographicContext'

const InfographicCategoriesList = ( ) => {
    
    /* Context */
    const { categoryList } = useContext( InfographicContext );

    /* Slider */
    const slider = document.querySelector('.slider');
    var isDown = false;
    var startX;
    var scrollLeft;

    const sliderOnMouseDown = e => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    };

    const sliderOnMouseUp = () => {
        isDown = false;
        slider.classList.remove('active');
    };

    const sliderOnMouseMove = e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 1.5;
        slider.scrollLeft = scrollLeft - walk;
    };

    return (
        <div id="infographic-categories-list" className="slider" onMouseDown={ sliderOnMouseDown } onMouseUp={ sliderOnMouseUp } onMouseMove={ sliderOnMouseMove }>
            <ul id="infograpic-categories-ul">
                { categoryList[0] && categoryList.map( category => {
                    return(
                        <li key={ category.key }><InfographicCategoryCard name={ category.name } categoryKey={ category.key } categoryID={ category.id } photoURL={ category.photoURL } /></li>
                    )
                }) }
                <li><AddNewInfographicCategory /></li>
            </ul>
        </div>     
    )
}

export default InfographicCategoriesList
