import React, { useContext } from 'react'
import './user.css'
import { AuthContext } from '../../../auth/Auth'

const User = () => {
    const { name, lastName, title, frame } = useContext(AuthContext);
    return (
        <div>
            <div id="profile-picture-border" className={frame}>
                <div id="profile-picture">
                </div>
            </div>
            <div id="user-info">
                <p id="user-name"> {name + " " + lastName} </p>
                <p id="user-title"> {title} </p>
            </div>
        </div>
    )
};

export default User;