import React, { useEffect, useState, useContext } from 'react';
import Ingredients from './Ingredients';
import { db } from '../../../../../config/fbConfig';
import { AuthContext } from '../../../../auth/Auth'

export const IngredientContext = React.createContext();

export const IngredientProvider = () => {
    const { name, lastName, uid } = useContext(AuthContext);


    /* Base States */
    const componentStateBase = {
        updating: false,
        nutrientValidation: true,
        macroValidation: false,
        nutrientKey: 0
    };

    const currentIngredientBase = {
        allergens: {
            mollusks: false,
            crustaceans: false,
            fish: false,
            lupins: false,
            mustard: false,
            eggs: false,
            gluten: false,
            treeNuts: false,
            peanuts: false,
            dairy: false,
            sulphur: false,
            sesameGrains: false,
            celery: false,
            soy: false 
        },
        carbohydrates: {
            value: '',
            valid: false
        },
        fat: {
            value: '',
            valid: false
        },
        fiber: {
            value: '',
            valid: false
        },
        name: '',
        protein: {
            value: '',
            valid: false
        },
        recipe: '',
        sugars: {
            value: '',
            valid: false
        },
        saturatedFat: {
            value: '',
            valid: false
        }
    };

    const nutrientsBase = [];

    const macroListBase = ['fiber', 'protein', 'carbohydrates', 'sugars', 'fat', 'saturatedFat'];

    /* State Definition */
    const [ componentState, setComponentState ] = useState( componentStateBase );
    const [ currentIngredient, setCurrentIngredient ] = useState( currentIngredientBase );
    const [ nutrients, setNutrients] = useState( nutrientsBase );
    const [ ingredientList, setIngredientList ] = useState( [] );
    const [ macroList ] = useState( macroListBase );

    /* Effects */
    useEffect(() => {
        loadIngredientList()
    }, [])

    /* Methods */
    const addNewIngredientToDatabase = () => {
        var ingredient = { ...currentIngredient };
        var nutrientData = [ ...nutrients ];
        var ingredientStruct = {
            allergens: ingredient.allergens,
            name: ingredient.name,
            recipe: '',
            nutrients: []
        };
        for (var i = 0; i < macroList.length; i++) {
            var value = ingredient[ macroList[i] ].value;
            value = value.replace(' ', '');
            if (!(value.endsWith('g') || value.endsWith('l') || value.endsWith('L'))) {
                value = value + 'g';
            }
            ingredientStruct[ macroList[i] ] = value;
        };
        for ( i = 0; i < nutrientData.length; i++) {
            value = nutrientData[ i ].value;
            value = value.replace(' ', '');
            if (!(value.endsWith('g') || value.endsWith('l') || value.endsWith('L'))) {
                value = value + 'g';
            }
            ingredientStruct.nutrients.push({
                name: nutrientData[ i ].name,
                value
            })
        };
        const author = {
            uid,
            name: name + ' ' + lastName,
        };
        ingredientStruct = {
            ...ingredientStruct,
            author,
            createdAt: new Date(),
            lastModified: new Date()
        }
        db.collection('ingredients').add( ingredientStruct ).then(ref => {
            resetCurrentIngredientState();
            resetInputs();
            if (ingredientList) {
                const ingredientListData = [ ...ingredientList];
                ingredientListData.push({
                    name: ingredient.name,
                    id: ref.id
                });
                db.collection('lists').doc('ingredients').set({ 
                    array: ingredientListData 
                });
            } else {
                db.collection('lists').doc('ingredients').set({ 
                    array: [{
                        name: ingredient.name,
                        id: ref.id 
                    }]
                });
            }
        });
    };
    const addNutrient = () => {
        var compState = { ...componentState };
        const nutrientPrototype = {
            key: compState.nutrientKey,
            name: '',
            value: '',
            valid: false
        }
        var state = [ ...nutrients ];
        compState.nutrientValidation = false;
        compState.nutrientKey = compState.nutrientKey + 1;
        state.push( nutrientPrototype );
        setNutrients( state );
        setComponentState( compState );
    };
    const deleteIngredient = () => {
        const ingredientID = componentState.updating;
        db.collection( 'ingredients' ).doc( ingredientID ).delete().then(() => {
            resetCurrentIngredientState();
            resetInputs();
        });
        db.collection('lists').doc('ingredients').get().then(doc => {
            const ingredientList = [ ...doc.data().array ];
            for (var i = 0; i < ingredientList.length; i++) {
                if (ingredientList[i].id === ingredientID) {
                    ingredientList.splice( i, 1 );
                    break;
                }
            };
            db.collection('lists').doc('ingredients').set({ 
                array: ingredientList 
            });
        });
    };
    const deleteNutrient = ( nutrientKey ) => {
        var state = [ ...nutrients ];
        var compState = { ...componentState };
        const key = findKeyInArray( state, nutrientKey );
        state.splice( key );
        compState.nutrientValidation = validateAllNutrients( state ); 
        setNutrients( state );
        setComponentState( compState );
    }
    const findKeyInArray = ( array, keyQuery ) => {
        var newKey = 0;
        for (var i = 0; i < array.length; i++) {
            if ( keyQuery === array[i].key ) {
                newKey = i;
                break;
            }
        }
        return newKey;
    };
    const loadIngredient = ( ingredientID ) => {
        db.collection( 'ingredients' ).doc( ingredientID ).get().then(doc => {
            const ingredient = doc.data();
            const currentIngredientData = {
                name: ingredient[ 'name' ],
                allergens: ingredient[ 'allergens' ],
                carbohydrates: { 
                    value: ingredient[ 'carbohydrates' ],
                    valid: true 
                },
                fat: { 
                    value: ingredient[ 'fat' ],
                    valid: true 
                },
                fiber: { 
                    value: ingredient[ 'fiber' ],
                    valid: true 
                },
                recipe: '',
                sugars: { 
                    value: ingredient[ 'sugars' ],
                    valid: true 
                },
                saturatedFat: { 
                    value: ingredient[ 'saturatedFat' ],
                    valid: true 
                },
                protein: {
                    value: ingredient[ 'protein' ],
                    valid: true 
                }
            };
            const nutrientData = ingredient[ 'nutrients' ];
            for (var i = 0; i < nutrientData.length; i++) {
                nutrientData[ i ] = {
                    ...nutrientData[ i ],
                    key: i,
                    valid: true                    
                }
            }
            const compState = componentState;
            compState.nutrientKey = i;
            compState.nutrientValidation = true;
            compState.macroValidation = true;
            setCurrentIngredient( currentIngredientData );
            setNutrients( nutrientData );
            setComponentState( compState );
            parseIngredientDataIntoInputs( currentIngredientData, nutrientData ); 
        });
        
        setUpdateState(ingredientID);
    };
    const loadIngredientList = () => db.collection('lists').doc('ingredients').onSnapshot(doc => {
        if (doc.data()) {
            setIngredientList( [ ...doc.data().array ] );
        };
        console.log('read')
    });
    const parseIngredientDataIntoInputs = ( currentIngredientData, nutrientData ) => {
        document.querySelector( '#ingredient-form input#name' ).value = currentIngredientData.name;
        document.querySelector( '#ingredient-form input#recipe' ).value = currentIngredientData.recipe;
        var currentInput = {};
        for (var i = 0; i < macroList.length; i++) {
            currentInput = document.querySelector( `#ingredient-form input#${macroList[ i ]}` );
            currentInput.value = currentIngredientData[ macroList[ i ] ].value;
        };
        for ( i = 0; i < nutrientData.length; i++) {
            document.querySelector( `div#nutrients .key-${ i } input.name-field` ).value = nutrientData[ i ].name;
            document.querySelector( `div#nutrients .key-${ i } input.value-field` ).value = nutrientData[ i ].value;
        };
    };
    const resetCurrentIngredientState = () => {
        setCurrentIngredient( currentIngredientBase );
        setNutrients( nutrientsBase );
        setComponentState( componentStateBase );
    };
    const resetInputs = () => {
        const inputs = document.querySelectorAll('#ingredient-form input');
        resetCurrentIngredientState();
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].value = '';
        };
    };
    const setUpdateState = ( value ) => {
        var compState = componentState;
        compState.updating = value;
        setComponentState( compState );
    };
    const updateAllergen = ( targetID ) => {
        var state = {
            ...currentIngredient,
            allergens: {
                ...currentIngredient.allergens,
                [targetID]: !currentIngredient.allergens[targetID]
            }
        };
        setCurrentIngredient( state );
    };
    const updateIngredientInDatabase = () => {
        var ingredient = { ...currentIngredient };
        var nutrientData = [ ...nutrients ];
        var ingredientStruct = {
            allergens: ingredient.allergens,
            name: ingredient.name,
            recipe: '',
            nutrients: []
        };
        for (var i = 0; i < macroList.length; i++) {
            var value = ingredient[ macroList[i] ].value;
            value = value.replace(' ', '');
            if (!(value.endsWith('g') || value.endsWith('l') || value.endsWith('L'))) {
                value = value + 'g';
            }
            ingredientStruct[ macroList[i] ] = value;
        };
        for ( i = 0; i < nutrientData.length; i++) {
            value = nutrientData[ i ].value;
            value = value.replace(' ', '');
            if (!(value.endsWith('g') || value.endsWith('l') || value.endsWith('L'))) {
                value = value + 'g';
            }
            ingredientStruct.nutrients.push({
                name: nutrientData[ i ].name,
                value
            })
        };
        const author = {
            uid,
            name: name + ' ' + lastName,
        };
        ingredientStruct = {
            ...ingredientStruct,
            author,
            createdAt: new Date(),
            lastModified: new Date()
        }
        db.collection( 'ingredients' ).doc( componentState.updating ).set( ingredientStruct ).then(() => {
            resetCurrentIngredientState();
            resetInputs();
            const compState = componentState ;
            const ingredientListData = ingredientList;
            for ( var i = 0; i < ingredientListData.length; i++ ) {
                if (ingredientListData[ i ].id === compState.updating) {
                    ingredientListData[ i ].name = ingredientStruct.name;
                    break;
                };
            };
            db.collection('lists').doc('ingredients').set({ 
                array: ingredientListData 
            }).then(() => {
                document.querySelector( `#ingredient-list #${ compState.updating }` ).classList.remove( 'active' )
            });
        });
    };
    const updateMacro = ( macro, value ) => {
        const valid = validateMacro( value );
        const state = {
            ...currentIngredient,
            [macro]: {
                value,
                valid
            }
        };
        validateAllMacros( state );
        setCurrentIngredient( state );
    };
    const updateName = ( name ) => {
        var state = {
            ...currentIngredient,
            name
        };
        validateAllMacros( state );
        setCurrentIngredient( state );
    };
    const updateNutrient = ( nutrientKey, field, value ) => {
        var state = [ ...nutrients ];
        const key = findKeyInArray( state, nutrientKey );
        var currentNutrient = {
            ...state[ key ],
            [ field ]: value
        };
        currentNutrient.valid = validateNutrient( currentNutrient );
        state[ key ] = currentNutrient;
        setNutrients( state );
        validateAllNutrients( state );
    };

    /* Field Verification */
    const validateAllNutrients = ( state ) => {
        var compState = { ...componentState };
        for (var i = 0; i < state.length; i++) {
            compState.nutrientValidation = true;
            if (!state[i].valid) {
                compState.nutrientValidation = false;
                break;
            };
        };
        setComponentState( compState );
    };
    const validateAllMacros = ( state ) => {
        var compState = { ...componentState };
        if (state.name) {
            compState.macroValidation = true;
            for (var i = 0; i < macroList.length; i++) {
                if (!state[ macroList[i] ].valid) {
                    compState.macroValidation = false;
                    break;
                }
            }
        } else {
            compState.macroValidation = false;
        }
        setComponentState( compState );
    };
    const validateNutrient = ( currentNutrient ) => {
        const regEx = /^([0-9]+)\s*(m*[glL])*$/;
        const validValue = regEx.test(currentNutrient.value);
        var compState = { ...componentState };
        var valid = false;
        if (currentNutrient.name && validValue ) {
            valid = true;

        }
        compState.valid = false;
        return valid;
    };
    const validateMacro = ( value ) => {
        const regEx = /^([0-9]+)\s*(m*[glL])*$/;
        const valid = regEx.test( value );
        return valid
    };

    /* Value Definition */
    var value = {
        addNewIngredientToDatabase,
        addNutrient,
        componentState,
        currentIngredient,
        deleteIngredient,
        deleteNutrient,
        ingredientList,
        loadIngredient,
        nutrients,
        resetCurrentIngredientState,
        resetInputs,
        setUpdateState,
        updateAllergen,
        updateIngredientInDatabase,
        updateMacro,
        updateName,
        updateNutrient
    };

    return (
        <IngredientContext.Provider value={ value }>
            <Ingredients />
        </IngredientContext.Provider>
    )
};