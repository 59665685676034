import React from 'react'
import { NavLink } from 'react-router-dom'

const AdminLinks = () => {
    return (
        <ul>
            <li><NavLink to="/dashboard">Dashboard</NavLink></li>
            <li><NavLink to="/infografias">Infografías</NavLink></li>
            <li><NavLink to="/menus">Menús semanales</NavLink></li>
            <li><NavLink to="/nuevomenu">Nuevo Menú</NavLink></li>
            <li><NavLink to="/recetas">Recetas</NavLink></li>
            <li><NavLink to="/nuevareceta">Nueva Receta</NavLink></li>
            <li><NavLink to="/ingredientes">Ingredientes</NavLink></li>
        </ul>
    )
}

export default AdminLinks
