import React, { useContext } from 'react'
import User from './User'
import UserLinks from './user/UserLinks'
import AdminLinks from './admin/AdminLinks'
import { NavLink } from 'react-router-dom'
import './sidebar.css'
import firebase from 'firebase'
import { AuthContext } from '../../../auth/Auth'

const Sidebar = () => {
    const { admin } = useContext(AuthContext);
    return (
        <div id="sidebar" className="simple-bg">
            <div className="padding30">
                <User />
                <nav className="sidebar-nav">
                    <div className="spacer1"></div>
                        {admin ? <AdminLinks /> : <UserLinks />}
                    <div className="spacer1"></div>
                        <ul>
                            <li><NavLink to="/perfil">Mi perfil</NavLink></li>
                            <li><a href="/" onClick={() => firebase.auth().signOut()}>Cerrar sesión</a></li>
                        </ul>
                </nav>
            </div>
            <div className="grad-1"></div>
        </div>
    )
}

export default Sidebar;