import React from 'react'
import '../mainContent.css'

const RecipeContent = (props) => {
    const id = props.match.params.id;
    return (
        <div class="content-wrapper">
            <div className="recipe-content">
                <h1>It works! ID={id}</h1>
            </div>
        </div>

    )
}

export default RecipeContent