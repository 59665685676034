import React from 'react';
import { withRouter } from 'react-router';
import { Component } from 'react';
import { ReactComponent as MainLogo } from '../../../img/logo-main.svg';
import "./preland.css"
import firebase from 'firebase'
import { db, funcs } from '../../../config/fbConfig';

class PreLand extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSignIn = (e) => {
        e.preventDefault();
        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(this.props.history.push("/")).catch(function(error) {
            // Handle Errors here.
            console.log(error)
            // ...
          });
    }
    newUser = (e) => {
        e.preventDefault();
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(cred => {
            const user = cred.user;
            const setAdminAndTier = funcs.httpsCallable('setAdminAndTier');
            user.updateProfile({
                displayName: "danielmontes",
                photoURL: ""
            });
            return setAdminAndTier({email: this.state.email,admin: true, tier: 1}).then(result => {
                db.collection('users').doc(user.uid).set({ name: 'Carmen',
                    lastName: 'F. Sena',
                    title: 'Nutricionista',
                    frame: 'admin',
                    initials: 'CS'
                });
            });
        });
    };
    render() {
        return(
            <div className="simple-bg" id="preland-div">
                <div className="bg-padding">
                    <div className="logo">
                        <MainLogo />
                    </div>
                    <form onSubmit={this.handleSignIn} id="login-form" name="login-form">
                            <input onChange={this.handleChange} type="email" id="email" placeholder="Correo electrónico"/>
                            <input onChange={this.handleChange} type="password" id="password" placeholder="Contraseña"/>
                            <div className="align-right">
                                <button className="button-2" type="" onClick={this.newUser}>Nuevo Usuario</button>
                                <button className="button-1 margin-l6" type="submit">Entrar</button>
                            </div>
                    </form>
                </div>
                <div className="grad-1"></div>
            </div>
        )
    }
}

export default withRouter(PreLand);