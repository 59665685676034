import React from 'react'
import './mainContent.css'

const Perfil = () => {
    return (
        <div class="content-wrapper">
            <h1>Mi perfil</h1>
        </div>
    )
}

export default Perfil
