import React, { useContext } from 'react'
import { AuthContext } from '../../../auth/Auth'
import { Switch, Route } from 'react-router-dom'
import InfographicsWidget from './infographics/InfographicsWidget'
import Menu from './Menu'
import Recipes from './recipes/Recipes'
import RecipeContent from './recipes/RecipeContent'
import Dashboard from './Dashboard'
import NuevoMenu from './NuevoMenu'
import Menus from './Menus'
import NewRecipe from './recipes/NewRecipe'
import IngredientsWidget from './ingredients/IngredientsWidget'
import Perfil from './Perfil'
import './mainContent.css'

const MainContent = () => {
    const { admin } = useContext(AuthContext);
    return (
        <div id="main-content" className={admin ? "admin" : ""}>
            <Switch>
                <Route exact path='/menu' component={Menu}/>
                <Route exact path='/recetas'  component={Recipes}/>
                <Route exact path='/infografias'  component={InfographicsWidget}/>
                <Route exact path='/dashboard' component={Dashboard}/>
                <Route exact path='/menus' component={Menus}/>
                <Route exact path='/nuevomenu' component={NuevoMenu}/>
                <Route exact path='/nuevareceta' component={NewRecipe}/>
                <Route exact path='/ingredientes' component={IngredientsWidget}/>
                <Route exact path='/perfil' component={Perfil}/>
                <Route exact path='/recetas/:id' component={RecipeContent}/>
            </Switch>
        </div>
    )
}

export default MainContent
