import React from 'react';
import { Component } from 'react';
import { ReactComponent as MagnifyingGlass } from '../../../img/icons/magnifying-glass.svg'

class SearchBar1 extends Component {
    state = {
        query: ''
    }
    handleChange = (e) => {
        this.setState({
            query: e.target.value
        })
        console.log(this.state)
    }
    render() {
        return(
            <div className="search-container">
                <div className="search-icon"><MagnifyingGlass /></div>
                <input onChange={this.handleChange} type="text" className="searchbox" placeholder="Buscar"/>
            </div>
        )
    }
}

export default SearchBar1;