import React, { useEffect, useState } from 'react';
import { db, storage } from '../../../../../config/fbConfig';
import AddInfographics from './AddInfographics';
import { set } from 'animejs';

export const InfographicContext = React.createContext();

export const InfographicProvider = () => {
    /* Base States */
    const currentInfographicBase = {
        title: '',
        category: '',
        createdAt: '',
        content: '',
        key: 0,
        downloadableFile: {
            isUploaded: false,
            name: '',
            fileURL: ''
        },
        infoNo: 0
    };

    const currentInfographicPicturesBase = [];

    const infographicsStateBase = {
        isInfographicEditorOpen: false,
        updatingInfographic: false,
        selectedCategory: '',
        selectedPictureKey: 0 
    };

    /* Effects */
    useEffect(() => {
        loadCategoryList();
        loadInfographicList();
        console.log( 'load' )
    }, [])

    /* State Definition */
    const [ categoryList, setCategoryList ] = useState( [] );
    const [ categoryListKey, setCategoryListKey ] = useState( 0 );
    const [ categoryListValidation, setCategoryListValidation ] = useState( true ); 
    const [ currentInfographic, setCurrentInfographic ] = useState( currentInfographicBase );
    const [ currentInfographicPictures, setCurrentInfographicPictures ] = useState( currentInfographicPicturesBase );
    const [ infographicsState, setInfographicsState ] = useState( infographicsStateBase );
    const [ infographicList, setInfographicList ] = useState( [] );
    const [ filteredInfographicList, setFilteredInfographicList ] = useState( [] );
    const [ infographicListKey, setInfographicListKey ] = useState( 0 ); 

    /* Methods */
    const addCategory = () => {
        var categories = [...categoryList];
        var key = categoryListKey;
        categories.push({
            id: 'provID' + key,
            key,
            name: '',
            photoURL: '',
            updatedPhoto: false,
            valid: false
        });
        key++;
        setCategoryList( categories );
        setCategoryListKey( key );
        setCategoryListValidation( false );
    };
    const addNewInfographic = () => {
    };
    const changeSelectedImage = ( pictureKey, photoURL ) => {
        var infoState = { ...infographicsState };
        infoState.selectedPictureKey = pictureKey;
        setInfographicsState( infoState );
        document.querySelector(`#current-image`).style.backgroundImage = `url( ${ photoURL } )`;
    };
    const closeInfographicEditor = () => {
        document.querySelector('#add-infographic-wrapper').classList.remove('active');
        setTimeout(() => {
            document.querySelector('#add-infographic-wrapper').classList.remove('open');
            document.querySelector('#image-selector').classList.remove('active');
            document.querySelector('#infographic-form').classList.remove('active');
        }, 300)
        var state = { ...infographicsState };
        state.isInfographicEditorOpen = false;
        state.updatingInfographic = false;
        setInfographicsState( state );
        resetCurrentInfographic();
    };
    const deleteInfographicFromDatabase = infographicID => {
        db.collection("infographics").doc( infographicID ).delete().then();
        closeInfographicEditor();
    }
    const editContent = ( value ) => {
        var state = currentInfographic;
        state.content = value;
        setCurrentInfographic( state )
    };
    const filterInfographicsList = ( categoryID, list = [ ...infographicList ]) => {
        var filteredList = [];
        if ( categoryID.length ) {
            filteredList = list.filter( item => item.category === categoryID );
        } else {
            filteredList = list;
        }
        setFilteredInfographicList( filteredList );
    };
    const handleUploadInfographicFile = e => {
        const file = e.target.files[ 0 ];
        if ( file ) {
            const uploadTask = storage.ref(`temp/${ file.name }`).put( file );
            uploadTask.on(
                "state_changed",
                snapshot => {},
                err => {
                    console.log( err );
                },
                () => {
                    storage.ref("temp")
                    .child( file.name )
                    .getDownloadURL()
                    .then( fileURL => {
                        var state = { ...currentInfographic };
                        state.downloadableFile = {
                            isUploaded: true,
                            name: file.name,
                            fileURL
                        };
                        setCurrentInfographic( state );
                    });
                }
            );  
        };
    };
    const loadCategoryList = () => {
        db.collection('infographic-categories').orderBy("name").onSnapshot( querySnapshot => {
            var categoryList = [];
            var i = 0;
            querySnapshot.forEach( doc => {
                categoryList.push({
                    id: doc.id,
                    key: i,
                    valid: true,
                    ...doc.data()                   
                });
                i++;
            });
            setCategoryList( categoryList );
            setCategoryListKey( i );
        })
    };
    const loadInfographic = infographicID => {
        var state = { ...infographicsState };
        const previousUpdatingID = state.updatingInfographic;
        if ( !state.isInfographicEditorOpen ) {
            state.isInfographicEditorOpen = true;
            openNewInfographicMenu();
        }
        state.updatingInfographic = infographicID;
        setInfographicsState( state );
        db.collection('infographics').doc( infographicID ).get().then( doc => {
            var infoState = { ...currentInfographicBase };
            var picState = [];
            const data = doc.data();
            infoState.category = data.category;
            document.querySelector('#add-infographic-wrapper #category-selector').value = infoState.category;
            infoState.title = data.title;
            document.querySelector('#add-infographic-wrapper #title').value = infoState.title;
            infoState.content = data.content;
            infoState.createdAt = data.createdAt;
            infoState.published = data.published;
            infoState.infoNo = data.infoNo;
            infoState.downloadableFile = {
                ...data.downloadableFile,
                isUploaded: true
            }
            for ( var i = infoState.key; i < data.pictures.length; i++ ) {
                picState.push({
                    key: i,
                    photoURL: data.pictures[ i ]
                });
                if ( !i ) {
                    document.querySelector(`#current-image`).style.backgroundImage = `url( ${ data.pictures[ i ] } )`;
                };
            };
            infoState.key = i;
            setCurrentInfographic( infoState );
            setCurrentInfographicPictures( picState );
            console.log( infoState )
        })
    }
    const loadInfographicList = () => {
        db.collection('infographics').orderBy("infoNo", "desc").onSnapshot( querySnapshot => {
            var infographicsList = [];
            var i = 0;
            var infoNo = 0;
            querySnapshot.forEach( doc => {
                infoNo = doc.data().infoNo;
                infographicsList.push({
                    category: doc.data().category,
                    title: doc.data().title,
                    id: doc.id,
                    key: i,
                    published: doc.data().published,
                    mainImage: doc.data().pictures[ 0 ],
                    infoNo,             
                });
                i++;
            });
            console.log( infographicsList )
            setInfographicList( infographicsList );
            filterInfographicsList( infographicsState.selectedCategory, infographicsList );
        })
    };
    const openInfographic = () => {};
    const openInfographicContentEditor = infographicID =>  {
        // Open Popup
        openInfographic( infographicID );
    };
    const openNewInfographicMenu = () => {
        document.querySelector('#add-infographic-wrapper').classList.add('active');
        setTimeout(() => {
            document.querySelector('#add-infographic-wrapper').classList.add('open');
            document.querySelector('#image-selector').classList.add('active');
            document.querySelector('#infographic-form').classList.add('active');
        }, 200)
        var state = { ...infographicsState };
        state.isInfographicEditorOpen = true;
        state.updatingInfographic = false;
        setInfographicsState( state );
    };
    const resetCurrentInfographic = () => {
        document.querySelector( '#infographic-form #title' ).value = '';
        document.querySelector( '#infographic-form #category-selector' ).value = '';
        setCurrentInfographic( currentInfographicBase );
        setCurrentInfographicPictures( currentInfographicPicturesBase );
    };
    const selectCategory = ( categoryID ) => {
        var state = { ...infographicsState };
        if ( state.selectedCategory !== categoryID ) {
            state.selectedCategory = categoryID;
            filterInfographicsList( categoryID );
        } else {
            state.selectedCategory = '';
            filterInfographicsList( '' );
        }
        setInfographicsState( state );
    };
    const unloadInfographic = ( infographicID, close = false ) => {
        var state = { ...infographicsState };
        if (close) {
            state.isInfographicEditorOpen = false;
        }
        state.updatingInfographic = false;
        setInfographicsState( state );
    }
    const updateCategoriesInDatabase = () => {
        const list = [ ...categoryList ];
        const regEx = /provID/;
        for ( var i = 0; i < list.length; i++ ) {
            var category = list[ i ];
            if ( regEx.test( category.id ) ) {
                db.collection( 'infographic-categories' ).add({
                    name: category.name,
                    photoURL: category.photoURL
                });
            } else {
                db.collection( 'infographic-categories' ).doc( category.id ).set({
                    name: category.name,
                    photoURL: category.photoURL
                });
            };
        };
        closeInfographicEditor();
    };
    const updateCategoryName = ( key, name ) => {
        var list = categoryList;
        list[ key ].name = name;
        if (name) {
            list[ key ].valid = true
        } else {
            list[ key ].valid = false    
        };
        setCategoryList( list );
        validateAllCategories( list );
    };
    const updateCategoryPicture = ( key, photoURL ) => {
        var list = categoryList;
        list[ key ].photoURL = photoURL;
        list[ key ].updatedPhoto = true;
        setCategoryList( list );
    };
    const updateCurrentInfographic = ( field, value ) => {
        var state = { ...currentInfographic };
        state[ field ] = value;
        setCurrentInfographic( state );
    };
    const uploadInfographicPhoto = e => {
        var files = Array.from( e.target.files );
        var pictureState = [ ...currentInfographicPictures];
        var infographicState = { ...currentInfographic };
        e.target.value = '';
        document.body.style.cursor = 'wait';
        uploadInfographicImage( files, pictureState, infographicState );
    };
    const uploadInfographicImage = ( files, pictureState, infographicState ) => {
        const numberOfCurrentImages = pictureState.length;
        const numberOfFiles = files.length;
        const newInfographic = ( numberOfCurrentImages ? false : true);
        const uploadTask = storage.ref(`temp/${ files[ 0 ].name }`).put( files[ 0 ] );
        uploadTask.on(
            "state_changed",
            snapshot => {},
            err => {
                console.log( err );
            },
            () => {
                storage.ref("temp")
                .child( files[ 0 ].name )
                .getDownloadURL()
                .then( photoURL => {
                    var image = document.createElement('img');
                    image.src = photoURL;
                    image.onload = () => {
                        files.splice( 0, 1 );
                        if ( newInfographic ) {
                        document.querySelector(`#current-image`).style.backgroundImage = `url( ${ photoURL } )`;
                        openNewInfographicMenu();
                        };
                        pictureState.push({
                            photoURL,
                            key: infographicState.key
                        });
                        infographicState.key++;
                        if ( numberOfFiles - 1 ) {
                            uploadInfographicImage( files,  pictureState, infographicState );
                        } else {
                            document.body.style.cursor = 'default';
                            setCurrentInfographicPictures( pictureState );
                            setCurrentInfographic( infographicState );
                        }
                    };
                });
            }
        );
    };
    const uploadInfographicToDatabase = e => {
        e.preventDefault();
        const state = { ...currentInfographic };
        var pictures = [];
        const currentPictures = [ ...currentInfographicPictures ];
        var published = false;
        if ( e.target.id === 'publish-infographic-button' ) {
            published = true;
        }
        for ( var i = 0; i < currentPictures.length; i++ ) {
            pictures.push( currentPictures[i].photoURL )
        }
        const infographicStruct = {
            title: state.title,
            category: state.category,
            content: state.content,
            downloadableFile: {
                name: state.downloadableFile.name,
                fileURL: state.downloadableFile.fileURL
            },
            pictures,
            published,
            infoNo: state.infoNo
        };
        if ( infographicsState.updatingInfographic ) {
            infographicStruct[ 'createdAt' ] = state.createdAt;
            db.collection( 'infographics' ).doc( infographicsState.updatingInfographic ).set( infographicStruct );
            var infoState = { ...infographicsState };
            infoState.updatingInfographic = e.target.id;
            setInfographicsState( infoState );
            console.log( infographicStruct )
        } else {
            infographicStruct[ 'createdAt' ] = new Date();
            infographicStruct[ 'infoNo' ] = infographicList[ 0 ].infoNo + 1;
            db.collection( 'infographics' ).add( infographicStruct ).then( doc => {
                var infoState = { ...infographicsState };
                infoState.updatingInfographic = doc.id;
                setInfographicsState( infoState );
            });
            var curr = { ...currentInfographic };
            curr.infoNo = infographicStruct.infoNo;
            setCurrentInfographic( curr )
        }
    };
    const validateAllCategories = list => {
        var valid = true;
        for ( var i = 0; i < list.length; i++ ) {
            if (!list[i].valid) {
                valid = false;
                break;
            };
        };
        setCategoryListValidation( valid );
    };

    /* Value Definition */
    var value = {
        addCategory,
        addNewInfographic,
        categoryList,
        categoryListValidation,
        changeSelectedImage,
        closeInfographicEditor,
        currentInfographic,
        currentInfographicPictures,
        deleteInfographicFromDatabase,
        editContent,
        filteredInfographicList,
        handleUploadInfographicFile,
        infographicList,
        infographicsState,
        loadInfographic,
        selectCategory, 
        unloadInfographic,
        updateCategoriesInDatabase,
        updateCategoryName,
        updateCategoryPicture,
        updateCurrentInfographic,
        uploadInfographicPhoto,
        uploadInfographicToDatabase
    };

    return (
        <InfographicContext.Provider value={ value }>
            <AddInfographics />
        </InfographicContext.Provider>
    )
};