import React from 'react'
import InfographicsList from './InfographicsList'

const Infographics = () => {
    return (
        <div id="infographics-wrapper">
            <InfographicsList />
        </div>
    )
}

export default Infographics
