import React from 'react'
import SearchBar1 from '../../SearchBar1'
import RecipeList from './RecipeList'
import '../mainContent.css'

const Recipes = () => {
    const recipes = "";
    return (
        <div className="content-wrapper">
            <div className="simple-bg border-radius-5-10">
                <div className="padding16">
                    <h1>Recetas</h1>
                </div>
                <div className="grad-1 no-border-radius"></div>
                <div id="searchbar1" className="dark-bg">
                    <SearchBar1/>
                </div>
            </div>
            <RecipeList recipes={recipes}/>
        </div>
    )
}

export default Recipes
