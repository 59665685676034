import React, { useContext, useEffect } from 'react'
import { InfographicContext } from './InfographicContext'
import AddNewImage from './AddNewImage'
import InfographicImageCard from './InfographicImageCard'

const InfographicPopupContent = () => {
    
    const { currentInfographic, editContent } = useContext( InfographicContext );
    const handleContentChange = e => {
        editContent( e.target.value )
    };

    return (
        <div id='popup-content' className="simple-bg" >
            <div>
                <h1>{ currentInfographic.title }</h1>     
                <textarea className='textarea-1' onChange={ handleContentChange } ></textarea>
            </div>
        </div>
    )
}

export default InfographicPopupContent
