import React, { useState } from 'react';

export const MainContext = React.createContext();

export const MainProvider = ({ children }) => {
    /* Base States */

    /* Effects */

    /* State Definition */
    const [ keyDown, setKeyDown ] = useState( null );

    /* Methods */
    const keyDownListener = e => {
        setKeyDown( e.key )
    };
    const keyUpListener = e => {
        setKeyDown( null )
    };

    /* Value Definition */
    var value = {
        keyDown,
        keyDownListener,
        keyUpListener
    };

    return (
        <MainContext.Provider value={ value }>
            { children }
        </MainContext.Provider>
    )
};