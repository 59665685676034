import React, { useContext, useEffect } from 'react'
import '../mainContent.css'
import { InfographicContext } from './InfographicContext';
import { MainContext } from '../../MainContext';
import { ReactComponent as PictureFrame } from '../../../../../img/icons/picture-frame.svg'
import { storage } from '../../../../../config/fbConfig'

const InfographicCard = ( props ) => {
    const { infographicsState, loadInfographic, unloadInfographic } = useContext( InfographicContext );

    const handleInfographicClick = e=> {
        e.preventDefault();
        if ( infographicsState.updatingInfographic === props.id ) {
            unloadInfographic( props.id );
        } else {
            loadInfographic( props.id );
        }
    };

    const cardClass = () => {
        var baseClass = "simple-bg infographic-card";
        if ( !props.published ) {
            baseClass = baseClass + ' unpublished'
        }
        if ( infographicsState.updatingInfographic === props.id ) {
            baseClass = baseClass + ' active'
        }
        return baseClass
    };

    /* Event Handling */
    return (
        <button className={ cardClass() } id={ "id" + props.id } onClick={ handleInfographicClick } style={{ backgroundImage: `url(${props.mainImage})`, }} >

        </button>
    )
}

export default InfographicCard