import React, { useContext, useEffect } from 'react'
import '../mainContent.css'
import { InfographicContext } from './InfographicContext';
import { MainContext } from '../../MainContext';
import { ReactComponent as PictureFrame } from '../../../../../img/icons/picture-frame.svg'
import { storage } from '../../../../../config/fbConfig'

const InfographicCategoryCard = ( props ) => {

    /* Effects */
    useEffect(() => {
       document.querySelector( `#id${props.categoryID} .infographic-category-name`).value = props.name;
    }, []);

    /* State Definition */
    const { infographicsState, updateCategoryName, updateCategoryPicture, selectCategory } = useContext( InfographicContext );
    const { keyDown, keyUpListener } = useContext( MainContext );

    /* Handle Events */
    const handleCategoryClick = e => {
        e.preventDefault();
        const categoryID = props.categoryID;
        selectCategory( categoryID );
    };
    const handleEditClick = e => {
        keyUpListener();
        e.preventDefault();
        document.querySelector(`#id${ props.categoryID } .infographic-category-photo-upload`).click();
    };
    const handleFileUpload = e =>{
        const file = e.target.files[0];
        if ( file ) { 
            const uploadTask = storage.ref(`temp/${ file.name }`).put( file );
            uploadTask.on(
                "state_changed",
                snapshot => {},
                err => {
                    console.log( err );
                },
                () => {
                    storage.ref("temp")
                    .child( file.name )
                    .getDownloadURL()
                    .then( photoURL => {
                        updateCategoryPicture( props.categoryKey, photoURL);
                        document.querySelector(`#id${props.categoryID} .infographic-category-photo`).style.backgroundImage = `url( ${ photoURL } )`;
                    });
                }
            )
        } else {
            console.log( 'Cancelled' )
        }
    };
    const handleNameChange = e => {
        updateCategoryName( props.categoryKey, e.target.value );
    };

    /* Event Handling */
    return (
        <div className={( infographicsState.selectedCategory === props.categoryID ? "infographic-category active" : "infographic-category" )} id={ "id" + props.categoryID }>
            <button className={ keyDown === 'Control' ? 'infographic-category-photo-wrapper editing' : 'infographic-category-photo-wrapper' } onClick={ keyDown === 'Control' ? handleEditClick : handleCategoryClick } >
                <div style={{backgroundImage: `url(${ props.photoURL })`}} className='infographic-category-photo'>
                </div>
                <div className="edit-icon-wrapper">
                    <PictureFrame/>
                </div>
            </button>
            <input type="file" className="infographic-category-photo-upload" onChange={handleFileUpload} accept="image/png, image/jpeg"></input>
            <input className="infographic-category-name" onChange={ handleNameChange } ></input>
        </div>
    )
}

export default InfographicCategoryCard