import React from 'react'
import { NavLink } from 'react-router-dom'

const UserLinks = () => {
    return (
        <ul>
            <li><NavLink to="/dashboard">Dashboard</NavLink></li>
            <li><NavLink to="/menu">Mi menú semanal</NavLink></li>
            <li><NavLink to="/recetas">Recetas</NavLink></li>
        </ul>
    )
}

export default UserLinks
