import React from 'react'
import '../mainContent.css'

const RecipeCard = ({ recipe }) => {
    let link = "#";
    if (recipe.id) {
        link = "recetas/" + recipe.id;
    }

    let name = "Nombre de la receta";
    if (recipe.name){
        name = recipe.name;
    }

    return (
        <div className="content-card">
            <a href={link}>
                <div className="recipe-img"></div>
                <div className="recipe-name"><p>{name}</p></div>
            </a>
        </div>    
    )
}

export default RecipeCard