import React from 'react'
import Ingredients from './Ingredients'
import { IngredientProvider } from './IngredientContext'

const IngredientsWidget = () => {
    return (
        <IngredientProvider>
            <Ingredients />
        </IngredientProvider>
    )
}

export default IngredientsWidget
