import React from 'react'
import RecipeCard from './RecipeCard'
import '../mainContent.css'

const RecipeList = ({ recipes }) => {
    return (
        <div id="recipe-list" className="">
            { recipes && recipes.map( recipe => {
                return(
                    <RecipeCard recipe={recipe} key={recipe.id}/>
                )
            })}
        </div>    
    )
}

export default RecipeList
