import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAZXCJvAMwHedvzz2Ypze0QaoZ3O8ydRyo",
  authDomain: "nutripeque-webapp.firebaseapp.com",
  databaseURL: "https://nutripeque-webapp.firebaseio.com",
  projectId: "nutripeque-webapp",
  storageBucket: "nutripeque-webapp.appspot.com",
  messagingSenderId: "413585858441",
  appId: "1:413585858441:web:7f1d8d2ab855dcafeccb58"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const funcs = firebase.functions();
const storage = firebase.storage();

export { db, funcs, storage };