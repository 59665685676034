import React, { useContext, useEffect, useState } from 'react'
import InfographicCategories from './InfographicCategories'
import { InfographicContext } from './InfographicContext'
import Infographics from './Infographics';
import AddNewInfographic from './AddNewInfographic';
import InfographicPopup from './InfographicPopup';
import useModal from '../../useModal'

const AddInfographics = () => {
    const {isShowing, hide, show} = useModal();

    const { addNewInfographic, categoryListValidation, closeInfographicEditor, currentInfographic,infographicsState, updateCategoriesInDatabase, uploadInfographicPhoto } = useContext( InfographicContext );

    const handleAddNewInfographicClick = e => {
        e.preventDefault();
        document.querySelector(`#infographics-button-row .new-infographic-photo-upload`).click();
    }

    const handleToggle = e => {
        show( currentInfographic.content );
    }

    return (
        <div id="content-wrapper" >
            <div className="simple-bg border-radius-5-10">
                <div className="padding16">
                    <h1>Infografías</h1>
                </div>
                <div className="grad-1 no-border-radius"></div>
                <div className="dark-bg">
                    <InfographicCategories/>
                    <div id="infographics-button-row">
                        <div></div>
                        <button className={ ( infographicsState.isInfographicEditorOpen === false || infographicsState.updatingInfographic === true ? 'button-2' : 'button-4' ) } onClick={ ( infographicsState.isInfographicEditorOpen === false || infographicsState.updatingInfographic === true ? handleAddNewInfographicClick : closeInfographicEditor ) } > { ( infographicsState.isInfographicEditorOpen === false || infographicsState.updatingInfographic === true ? 'Nueva Infografía' : 'Cerrar Editor' )  } </button>
                        <input type="file" className="new-infographic-photo-upload" onChange={ uploadInfographicPhoto } accept="image/png, image/jpeg" multiple={ true } ></input>
                        <button className={ categoryListValidation ? "button-1" : "button-1 deactivated" } onClick={ categoryListValidation ? updateCategoriesInDatabase : null } > Publicar </button>
                    </div>
                </div>
            </div>
            <AddNewInfographic togglePopup={ handleToggle } />
            <Infographics/>
            <InfographicPopup isShowing={ isShowing } hide={ hide }/>
        </div>
    )
}

export default AddInfographics
